
import { defineComponent, inject } from "vue";
import RoutingInteractor from "@interactors/routing/Routing.interactor";
import Sortable from 'sortablejs';
import router from "@frameworks/vue/router/clean";
import OptionsInteractor from "@interactors/options/Options.interactor";

export default defineComponent({
    name: "HomeOptions",
    components: {},
    watch: {
        '$route'(to, from) {
            const section = RoutingInteractor.getCurrentSection(false, false, true);
            if (section === "settings/home") {
                this.$mitt.emit("view-loading-start")
                this.section = RoutingInteractor.getCurrentSection(false, false, true) as string;
                this.subsection = RoutingInteractor.getCurrentSubsection(false, false, true) as string;
                this.subsections = RoutingInteractor.getSectionSubsections();
                this.initSortableList();
                this.options[this.subsection].sort((a, b) =>
                    a.options.order < b.options.order ? -1 : 1
                );
                this.$mitt.emit("view-loading-finish")
            }
        },
        options() {
            this.$nextTick(() => {
                this.initSortableList();
            })
        }
    },
    data() {
        const section = RoutingInteractor.getCurrentSection(false, false, true) as string;
        let options: any;

        let $mitt: any;
        const subsection = RoutingInteractor.getCurrentSubsection(false, false, true) as string;

        return {
            section,
            options,
            subsections: RoutingInteractor.getSectionSubsections(),
            subsection,
            $mitt,
        }
    },
    async mounted() {
        this.$mitt = inject('$mitt');
        this.$mitt.emit("view-loading-finish");

        this.$mitt.on("view-options-save-options", () => { this.saveOptions() });
        this.$mitt.on("home-options-restore-options-current", () => { this.restoreCurrentOptions() });
        this.$mitt.on("home-options-restore-options-all", () => { this.restoreAllOptions() });

        this.options = await OptionsInteractor.getSectionOptions(true);

    },
    beforeUnmount() {
        this.$mitt.all.delete("view-options-save-options", () => { this.saveOptions() });
        this.$mitt.all.delete("home-options-restore-options-current", () => { this.restoreCurrentOptions() });
        this.$mitt.all.delete("home-options-restore-options-all", () => { this.restoreAllOptions() });
    },
    methods: {
        saveOptions() {
            OptionsInteractor.updateSectionOptions(this.options);
            router.push("/home/" + this.subsection);
        },
        async restoreCurrentOptions() {
            console.log('restoring current options');
            OptionsInteractor.restoreSectionOptions(false);
            let options = this.options;
            options[this.subsection] = await OptionsInteractor.getSectionOptions();
            this.options = { ...options };
            return;
        },
        async restoreAllOptions() {
            OptionsInteractor.restoreSectionOptions(true);
            this.options = await OptionsInteractor.getSectionOptions(true);
            return;
        },
        updateOptions(index, data, setting, isType = false) {
            if (isType) {
                this.options[this.subsection][index]['type'] = data;
                return
            }

            this.options[this.subsection][index]['options'][setting] = data;
            return
        },
        handleInputMouseEnter(title: string) {
            const imitationEl = document.getElementById('imitation_' + title.replaceAll(' ', '-'));
            if (imitationEl) {
                imitationEl.style.border = 'solid #fff 1px';
                imitationEl.style.backgroundColor = '#009EF7';
                imitationEl.style.color = '#fff';
                imitationEl.style.transform = 'scale(1.015)';
                imitationEl.style.boxShadow = 'rgba(0, 0, 0, 0.24) 0px 3px 8px';
            }
        },
        handleInputMouseLeave(title) {
            const imitationEl = document.getElementById('imitation_' + String(title).replaceAll(' ', '-'));
            if (imitationEl) {
                imitationEl.style.border = '';
                imitationEl.style.backgroundColor = '';
                imitationEl.style.color = '';
                imitationEl.style.transform = '';
                imitationEl.style.boxShadow = '';
            }
        },
        getChartComponentName(type) {
            return type;
        },
        getChartSize(chartCategory) {
            switch (chartCategory) {
                case 'HomeProgress':
                    return 'silhouette-100-25';
                case 'InfoCards':
                    return 'silhouette-100-35';
                case 'SubStatus':
                    return 'silhouette-100-30';
                case 'Announcements':
                    return 'silhouette-100-35';
                case 'LatestGuides':
                    return 'silhouette-100-50';
                default:
                    return 'silhouette-100-50';
            }
        },
        isLastCheckedChart(title: string) {
            let totalEnabledCharts: any[] = [];
            for (let option in this.options[this.subsection]) {
                if (this.options[this.subsection][option].options.isEnabled) {
                    totalEnabledCharts.push(this.options[this.subsection][option].title);
                }
            }

            if (totalEnabledCharts.length < 2 && totalEnabledCharts.includes(title)) {
                return true;
            }
            return false;
        },
        initSortableList() {

            const updateOptions = (options) => {
                this.options[this.subsection] = options;
            };

            const getCurrentOptions = () => {
                return this.options[this.subsection];
            }

            new Sortable(document.getElementById('dashboard-options-list'), {
                'sort': true,
                onUpdate: function (evt) {

                    const currentOptions = getCurrentOptions();

                    const children: HTMLCollection | undefined = document.getElementById('dashboard-options-list')?.children;
                    if (!children) {
                        return;
                    }

                    for (let i = 0; i < children.length; i++) {
                        const child: any = children[i];
                        for (let option in currentOptions) {
                            if (currentOptions[option].title === child.children[0].value) {
                                currentOptions[option].options.order = i;
                            }
                        }
                    }

                    updateOptions(currentOptions);

                    return;
                },
                ignore: 'ul'
            });

            return;
        },
    },
});
