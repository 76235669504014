import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container-xxl h-100 modal-body dashboard-options-responsive-flex align-middle justify-content-center align-items-center overflow-y-scroll px-0 px-lg-4" }
const _hoisted_2 = { class: "h-max-con py-4 px-6" }
const _hoisted_3 = { class: "h-100 w-100 rounded p-4 overflow-y-scroll bg-white" }
const _hoisted_4 = {
  key: 0,
  id: "dashboard-options-list",
  class: "list-group list-group-flush h-100 w-100"
}
const _hoisted_5 = ["onMouseenter", "onMouseleave"]
const _hoisted_6 = ["value", "disabled", "onUpdate:modelValue"]
const _hoisted_7 = { class: "h-100 py-4 px-6" }
const _hoisted_8 = { class: "h-100 w-100 rounded p-4 overflow-y-scroll bg-white overflow-y-scroll" }
const _hoisted_9 = {
  key: 0,
  class: "dashboard-imitation-flex-col"
}
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.options)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options[_ctx.subsection], (block) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: block,
                  onMouseenter: ($event: any) => (_ctx.handleInputMouseEnter(block.title)),
                  onMouseleave: ($event: any) => (_ctx.handleInputMouseLeave(block.title)),
                  class: "list-group-item chart-options-item position-relative form-check form-switch m-0 cursor-grab"
                }, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input form-check-custom form-check-blue ms-0 me-4",
                    type: "checkbox",
                    role: "switch",
                    value: block.title,
                    disabled: _ctx.isLastCheckedChart(block.title),
                    "onUpdate:modelValue": ($event: any) => (block.options.isEnabled = $event)
                  }, null, 8, _hoisted_6), [
                    [_vModelCheckbox, block.options.isEnabled]
                  ]),
                  _createTextVNode(" " + _toDisplayString(block.title), 1)
                ], 40, _hoisted_5))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_ctx.options)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options[_ctx.subsection], (block, index) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass([[{ 'd-none': !block.options.isEnabled, }, _ctx.getChartSize(_ctx.getChartComponentName(block.component))], "chart-imitation rounded d-flex justify-content-center align-content-center align-items-center h3 chart-imitation-name mb-0"]),
                  id: 'imitation_' + String(block.title).replaceAll(' ', '-'),
                  style: _normalizeStyle('order:' + block.options.order)
                }, _toDisplayString(block.title), 15, _hoisted_10)), [
                  [_vShow, block.options.isEnabled]
                ])
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}